// Here you can add other styles
.btn-outline-warning {
    color: #ec971f;
    background-color: transparent;
    background-image: none;
    border-color: #ec971f;

    &:hover {
        color: #fff;
        background-color: #ec971f;
    }

    &.focus, &:focus {
        color: #fff !important;
        background-color: #ec971f !important;
    }

    &.active, &:active {
        color: #fff !important;
        background-color: #ec971f !important;
    }
}

.floating-loading .loading {
    position: absolute;
    z-index: 50;
    left: 50%;

    .sk-circle::before{
        box-shadow: rgba(0,0,0, .5) 0 0 30px, #ffffff 0 0 20px, #ffffff 0 0 20px, #ffffff 0 0 20px, #ffffff 0 0 20px;
    }
}

.loading.small {
    position: relative;
    height: 40px;
    width: 40px;
    .sk-fading-circle{
        position: absolute;
        top: -34px;
        left: 6px;
        margin: 0;
        width: 28px;
        height: 28px;
    }

}

.DateRangePicker_picker{
    z-index: 999 !important;
}

@media screen and (min-width: 1401px) {
  h3.responsive-label-text {
    font-size: 28px;
  }
}

@media screen and (max-width: 1400px) {
  h3.responsive-label-text {
    font-size: 3vw;
  }
}

@media screen and (min-width: 751px) {
  .responsive-label-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .responsive-label-text {
    font-size: 3.5vw;
  }
}

.toast_container {
  z-index: 4000;
}


.login-tag {
  display: inline-block;
  vertical-align: middle;
  > * {
    line-height: 1.2em;
  }

  > .account {
    font-size: .77em;
  }

}

.productIcon-tooltip-arrow {
  border-top-color: #20a8d8;
  border-left-color: #20a8d8;
  border-right-color: #20a8d8;
  border-bottom-color: #20a8d8;
}

.productIcon-tooltip-inner {
  background: #20a8d8;
  color: whitesmoke;
}

.productIcon-product-not-active {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.reference-image {
    cursor: pointer;
    position: relative;

    &.box {
        width: 80px;
        height: 100px;
        line-height: 100px;

        &.disabled {
            background-color: #f6f6f6;
        }

        > span {
            padding: 4px;
            font-size: 12px;
            display: inline-block;
            line-height: 1.2em;
            vertical-align: baseline;

            > div.animated > .sk-fading-circle {
                transform: translateY(-150%);
            }
        }
    }
}

.current-image-section {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: 0px auto;

  &--timestamp {
    padding: 8px;
    text-align: center;
    font-weight: bold;
    background-color: #444;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tracker-reference-thumbnail {
    width: 150px; /* or any custom size */
    height: 80px;
    object-fit: fill;
}

.tracker-section-photo {
    width: 100%;
    margin: 0px auto;

    &.box {
        padding-top: 34px;
        text-align: center;
        // background-color: red;
        // width: 300px;
        // height: 400px;
    }
}

.thumbnail {
    margin-bottom: 10px;
    margin-right: 5px;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    &.active {
        border: 4px solid #50C878;
    }

    &:hover {
      box-shadow: 0 0 2px 1px rgba(80, 200, 120, 1);
    }
}

.thumbnail-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: auto;
}


.loader-container {
  position: relative;
  
  > .loading {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100vh;
    background: rgba(0, 0, 0, .25);
    display: flex;
    justify-content: center;
    align-items: center;
    > .sk-fading-circle {
      transform: translateY(-40px);
    }
  }
}

.loading-timer-container {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  div.inline-loading {
    margin-bottom: 8px;
  }
}

.inline-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  &--text {
      font-size: 12px;
      margin-left: 32px;
  }
}

.checklist {
    padding: 0 10px;
    > label {
        &:hover {
            background-color: #00abd673;
        }

        display: block;
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 1.5rem;
    }
}



.btn-group-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
}

.marginless {
    margin: 0;
}


.end-caps{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}


.notifications-multi-select {
  flex: 1 1 auto;
  width: 1%;
}

.hide-on-small-screen {
  @media screen and (max-width: 480px) {
    display: None;
  }
}

.title-case {
  text-transform: capitalize;
}
