.led-set {
    > .led {
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;

        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 2px;

        &.is-red{
            border-color: #C0C0C0;
            background-color: darken(#ff0000, 30%);
            &.is-on {
                background-color: #ff0000;
            }
        }

        &.is-yellow{
            border-color: #C0C0C0;
            background-color: darken(#ffff00, 30%);
            &.is-on {
                background-color: #ffff00;
            }
        }

        &.is-green{
            border-color: #C0C0C0;
            background-color: darken(#00ff00, 30%);
            &.is-on {
                background-color: #00ff00;
            }
        }
    }

}
