

.location-card {
    > .card-body {
        position: relative;
        > .name {
            font-size: 1.3rem;
            margin-right: 46px;
        }
        > .status {
            display: inline-block;
            padding-right: 16px;
            width: 5em;
            // position: absolute;
            // right: 16px;
            // top: 16px;
        }
        > .products {
            display: inline-block;
        }
        > .expand {
            position: absolute;
            right: 16px;
            top: 40px;
            background: transparent;
            border-radius: 100%;
            border-color: transparent;
            &:focus {
                outline: none;
            }
        }
    }

    .btn-bar {
        display: flex;
        justify-content: space-around;
        margin: 10px 0px;

        > * {
            flex: 1 1 0;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}