
@media (max-width: $mobile-breakpoint){
    .main > .container-fluid {
        padding-left: 0;
        padding-right: 0;
        > .animated {
            > nav:first-child > ol.breadcrumb{
                margin-bottom: 0;
            }
            > nav + .card {
                margin-top: 0 !important;

                > .card-header {
                    background: transparent;
                }
            }
        }
    }
}
