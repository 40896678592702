


.topology-node {
    font-size: .875 rem;

    > .node-name {
        display: flex;
        > .node-icon {
            flex-basis: 16px;
            font-weight: normal;
            padding-top: 6px;
            padding-bottom: 6px;
        }

        > :last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        form {
            flex-grow: 1;
        }

        > * {
            border-radius: 0px;
        }
        
    }

    .node-name-text, > form {
        flex-grow: 1;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 4px;
        padding-right: 12px;
    }

    .node-name-text {
        display: flex;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        > span:first-child {
            flex-grow: 1;
        }

        > .w3 {
            flex-basis: 3em;
        }

        > .w5 {
            flex-basis: 5em;
        }

        > .w8 {
            flex-basis: 8em;
        }

        > .centered {
            display: flex;
            justify-content: center;
        }

        > * {
            flex-grow: 0;
            margin-left: 5px;
        }
        
        .badge {
            line-height: 1.5em;
            flex-basis: 2em;
            margin-left: 5px;
        }
    }

    &.is-selected {
        > .node-name > .node-name-text {
            font-weight: bold;
            background-color: #00abd673;
            color: #ffffff;
        }
    }

    &:not(.is-selected) > .node-name > .node-name-text:hover {
        background-color: #00abd673;
        color: #ffffff;
    }
}

.topology-node > .children, .topology-node-children {
    margin-left: 20px;
}

.topology-node {
    .edit-topology-form {
        // max-width: 400px;
    }
}


.node-icon {
    font-style: normal;

    &.italics {
        font-style: italics;
    }

    &.type-account::before {
        font-family: 'simple-line-icons';
        content: "\e069"; // .icon-home;
        margin-right: 1px;
    }

    &.type-location::before {
        font-family: 'FontAwesome';
        content: "\f041"; // .icon-home;
        margin-right: 1px;
    }

    &.type-topology-node {
        &.is-leaf::before {
            font-family: 'FontAwesome';
            content: "\f068"; // .icon-home;
            margin-right: 1px;
        }
        &:not(.is-leaf) {
            &::before {
                font-family: 'FontAwesome';
                // font-weight: normal;
                content: "\f07c"; // .icon-home;
                margin-right: 1px;
            }
        }
    }
}


.topology-select {
    &.dropdown {
        > .dropdown-menu {
            max-height: 80vh;
            min-width: 250px;
            overflow: auto;
        }
    }
}

@media (max-width: $mobile-breakpoint){
    .topology-select {
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 280px;
 
        &.dropdown {
            > .dropdown-menu {
                width: 100%;
                min-width: auto;
           }
        }
    }

}

.dropdown > button > .node-name {
    display: inline-block;
}