
.finder-window{
    display: flex;

    > .folder-pane {
        position: relative;
        flex-basis: 200px;
        padding: 5px 10px;
        overflow: auto;

        .node-name-text {
            span {
                white-space: nowrap;
            }
        }

        .expand-button {
            display: none;
        }

    }
    > .details-pane {
        border-left: 1px solid gray;
        flex-grow: 3;
        padding: 5px 10px;
    }
}
